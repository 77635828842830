import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useForm } from 'react-hook-form';
import Meta from '../../meta/Meta';
import { Helmet } from 'react-helmet-async';

const Career = () => {
    const pageDescription = "Discover exciting career opportunities at Al-Maghrib International School. Join a dedicated team, contribute to academic excellence, and nurture the future generation. Explore rewarding careers in education with us.";
    const pageImage = "https://i.ibb.co/gwkTRWk/new-School.png"
    const pageUrl = "https://almaghribschool.com/careers";
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = 'Career | Al-Maghrib';
    }, []);

    const [applyOnline, setApplyOnline] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sumbitted, setSubmitted] = useState(false);
    const [resumeUrl, setResumeUrl] = useState("");
    const [resumeName, setResumeName] = useState("");
    const [applicantName, setApplicantName] = useState("");


    const { register, handleSubmit, formState: { errors } } = useForm();


    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    // Update function name to reflect general file upload
    function uploadSingleFile(base64) {
        setLoading(true);

        const formData = new FormData();
        formData.append("file", base64);

        axios
            .post("https://al-maghrib-server.vercel.app/career/uploadFile", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((res) => {
                setResumeUrl(res.data);
            })
            .then(() => setLoading(false))
            .catch((error) => {
                // console.error("Error uploading file:", error);
                alert("The file you are trying to upload does not meet the requirements. Please ensure it is a PDF, less than 10MB, and the file name is visible. Refresh the page and apply again.")
                setLoading(false);
            });
    }


    // Update function name to reflect general file upload
    const uploadFile = async (event) => {
        const files = event.target.files;
        setResumeName(files[0].name);

        if (files.length === 1) {
            const base64 = await convertBase64(files[0]);
            uploadSingleFile(base64);
            return;
        }
    };

    const onSubmit = data => {
        data["resume_url"] = resumeUrl;
        setApplicantName(data.name)


        axios
            .post("https://al-maghrib-server.vercel.app/career/create-career-application", data, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                setSubmitted(true)
            })
            .then(() => setLoading(false))
            .catch((error) => {
                // console.error("Error submitting application:", error);
                alert("We are sorry! There is an error happening while submitting the application. Please refresh the page and apply again or contact our helpline.")
                setSubmitted(false);
            });


    }

    return (
        <>
            {/* <Meta pageTitle={"Career | Al-Maghrib"} pageDescription={pageDescription} pageImage={pageImage} pageUrl={pageUrl} /> */}
            <Helmet>
                {/* Common meta tags */}
                <title>Career | Al-Maghrib</title>
                <meta name="description" content={pageDescription} />

                {/* Open Graph meta tags for Facebook and other platforms */}
                <meta property="og:title" content="Career | Al-Maghrib" />
                <meta property="og:description" content={pageDescription} />
                <meta property="og:image" content={pageImage} />
                <meta property="og:url" content={pageUrl} />
                <meta property="og:type" content="website" />

                {/* Twitter Card meta tags */}
                <meta name="twitter:card" content="summary_large_image" />
                {/* <meta name="twitter:site" content={twitterUsername} /> */}
                <meta name="twitter:title" content="Career | Al-Maghrib" />
                <meta name="twitter:description" content={pageDescription} />
                <meta name="twitter:image" content={pageImage} />

                {/* Additional meta tags for various platforms */}
                <meta property="al:web:url" content={pageUrl} />
            </Helmet>


            <div>
                <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
                    style={{
                        minHeight: "45vh"
                    }}>
                    <div className="absolute top-0 w-full h-full bg-center bg-cover"
                        style={{
                            backgroundImage: "url('https://img.freepik.com/free-photo/we-are-hiring-concept_23-2150902122.jpg?size=626&ext=jpg&ga=GA1.1.162958315.1697884611&semt=ais')"
                        }}>
                        <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
                    </div>
                    <div className="container relative mx-auto">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full px-2 md:px-4 ml-auto mr-auto text-center">
                                <div className="">
                                    <h1 className="text-white font-semibold text-4xl md:text-5xl">
                                        Career at Al-Maghrib
                                    </h1>
                                    <p className="mt-4 text-lg text-gray-300">
                                        <strong>

                                        </strong> <br />
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <section className="pt-20 pb-20 bg-blue-50 -mt-24">
                    <div className="container mx-auto px-4 mt-5">
                        {
                            sumbitted ?
                                <div className="lg:pt-12 pt-6 w-full md:w-12/12 text-center">
                                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                        <div className="px-4 py-5 flex-auto">
                                            <div className={"text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-1 shadow-lg rounded-full  " + (sumbitted ? "bg-green-400" : "bg-blue-400")} >
                                                <FontAwesomeIcon icon={faMagnifyingGlass} size="lg" />
                                            </div>
                                            <h6 className="text-xl font-semibold">Quick Apply</h6>
                                            <div>
                                                <div className="flex items-center justify-center mt-5">
                                                    <h6 className="text-2xl font-semibold text-green-400">Thank You {applicantName}. We have received your application. We will get back to you within a few days InshaAllah.</h6>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <>
                                    <div className="lg:pt-12 pt-6 w-full md:w-12/12 text-center">
                                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                            <div className="px-4 py-5 flex-auto">
                                                <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                                                    <FontAwesomeIcon icon={faMagnifyingGlass} size="lg" />
                                                </div>
                                                {/* <h6 className="text-xl font-semibold">Career</h6> */}
                                                <p></p>
                                                <p className="mt-2 mb-4 text-gray-600 text-justify">
                                                    Almagib International School takes pride in its robust HR department, committed to fostering a
                                                    supportive work environment for our dedicated employees. Renowned for offering some of the most
                                                    competitive compensation benefits in the industry, our school recognizes the importance of valuing
                                                    and investing in its staff. Our HR team comprises specialized professionals who are adept at
                                                    addressing the unique needs of our diverse workforce. Through their efforts, we strive to create an
                                                    atmosphere that not only encourages professional growth but also ensures the overall well-being of
                                                    our valuable team members.
                                                    <br /> <br />
                                                    If you want to become part of Almaghrib team please apply by cleaking below.
                                                </p>
                                                <div className="flex justify-center">
                                                    <div className="mt-6">
                                                        {/* <Link to="/admission/online-form"> */}
                                                        <button
                                                            className="bg-blue-400 text-white active:bg-blue-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                                            type="button"
                                                            style={{ transition: "all .15s ease" }}
                                                            onClick={() => setApplyOnline(true)}
                                                        >
                                                            Apply Online
                                                        </button>
                                                        {/* </Link> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        applyOnline &&
                                        <div className="lg:pt-12 pt-6 w-full md:w-12/12 text-center">
                                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                                <div className="px-4 py-5 flex-auto">
                                                    <div className={"text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-1 shadow-lg rounded-full  " + (sumbitted ? "bg-green-400" : "bg-blue-400")} >
                                                        <FontAwesomeIcon icon={faMagnifyingGlass} size="lg" />
                                                    </div>
                                                    <h6 className="text-xl font-semibold">Quick Apply</h6>
                                                    <p className='text-sm'><i>Fillup all the fields. Your cv/resume file should be in .pdf format and &lt; 10MB.</i></p>
                                                    <div>
                                                        <form className="max-w-sm mx-auto " onSubmit={handleSubmit(onSubmit)}>
                                                            <div className="mb-5 mt-5">
                                                                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 ">Full Name</label>
                                                                <input type="text" id="name" {...register("name", { required: true })} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Your full name" required />
                                                                {errors.name && <span>This field is required</span>}
                                                            </div>
                                                            <div className="mb-5">
                                                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Email</label>
                                                                <input
                                                                    type="email"
                                                                    id="email"
                                                                    {...register("email", {
                                                                        required: true,
                                                                        // pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ 
                                                                    })}
                                                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                                                                    placeholder="Your email address"
                                                                    required
                                                                />
                                                                {errors.email && <span className='text-red-400'>This field is required and must be a valid email address</span>}
                                                            </div>
                                                            <div className="mb-5">
                                                                <label htmlFor="contact" className="block mb-2 text-sm font-medium text-gray-900 ">Contact No</label>
                                                                <input type="tel" id="contact" {...register("contact", { required: true })} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Your contact number" required />
                                                            </div>
                                                            <div className="mb-5">
                                                                <label htmlFor="career_position" className="block mb-2 text-sm font-medium text-gray-900 ">Position You are Seeking</label>
                                                                <input type="text" id="career_position" {...register("career_position", { required: true })} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="For which position you're interested" required />
                                                            </div>

                                                            {
                                                                loading ?
                                                                    <div className="flex items-center justify-center">
                                                                        <div role="status">
                                                                            <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                                            </svg>
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>

                                                                    </div>
                                                                    :
                                                                    !resumeUrl ?
                                                                        <div className="mb-5">
                                                                            <label className="block mb-2 text-sm font-medium text-gray-900">CV/Resume (.pdf format, &lt; 10MB)</label>
                                                                            <div className="bg-white p7 rounded w-9/12 mx-auto mt-3">
                                                                                <div className="relative flex flex-col p-4 text-gray-400 border border-gray-200 rounded">
                                                                                    <div className="relative flex flex-col text-gray-400 border border-gray-200 border-dashed rounded cursor-pointer">
                                                                                        <input
                                                                                            // accept="*"
                                                                                            accept=".pdf"
                                                                                            type="file"
                                                                                            // multiple
                                                                                            onChange={uploadFile}
                                                                                            className="absolute inset-0 z-50 w-full h-full p-0 m-0 outline-none opacity-0 cursor-pointer"
                                                                                            title=""
                                                                                            required
                                                                                        />

                                                                                        <div className="flex flex-col items-center justify-center py-10 text-center">
                                                                                            <svg className="w-6 h-6 mr-1 text-current-50" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                                                                stroke="currentColor">
                                                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                                                                    d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                                                                            </svg>
                                                                                            <p className="m-0">Drag your files here or click in this area (pdf format only).</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        :

                                                                        <div className="my-5 flex items-center justify-center">
                                                                            <p>{resumeName}</p>{" "}
                                                                        </div>

                                                            }

                                                            <div className="flex items-start mb-5">
                                                                <div className="flex items-center h-5">
                                                                    <input id="terms" type="checkbox" value="" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800" required />
                                                                </div>
                                                                {/* <label htmlFor="terms" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">I agree with the <a href="#" className="text-blue-600 hover:underline dark:text-blue-500">terms and conditions</a></label> */}
                                                                <label htmlFor="terms" className="ms-2 text-sm font-medium text-red-900 dark:text-red-300">I declare, all of the information above are correct.</label>
                                                            </div>

                                                            {/* <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                        Submit Application
                                                    </button> */}
                                                            <div className="text-center mt-6">
                                                                <button
                                                                    className="bg-blue-400 text-white active:bg-blue-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                                                    type="submit"
                                                                    style={{ transition: "all .15s ease" }}
                                                                >
                                                                    Submit Application
                                                                </button>
                                                            </div>
                                                            {/* <input type="submit" /> */}
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                        }
                    </div>
                </section>
            </div>
        </>
    );
};

export default Career;
import React, { useEffect } from 'react';
import ceo_pic from '../../../assets/img/board_members/ceo1.jpg';
import school from '../../../assets/img/school/newSchool.png';

const CEO = () => {
    useEffect(() => {
        document.title = "CEO | Al-Maghrib";
    }, []);
    return (
       <>
        <div>
            <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
                style={{
                    minHeight: "55vh"
                }}>
                <div className="absolute top-0 w-full h-full bg-center bg-cover"
                    style={{
                        backgroundImage: "url('https://img.freepik.com/premium-vector/modern-blue-abstract-background-vector-illustration-design-presentation-banner-cover-web-flyer-card-poster-wallpaper-texture-slide-magazine-powerpoint_181182-19659.jpg?w=900')"
                    }}>
                    <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
                </div>

                <div className="container  relative mx-auto">
                    <div className="items-center flex flex-wrap">
                        <div className="mt-5 flex flex-col items-center md:flex-row  px-12" >
                            <div className="w-full md:w-6/12 lg:w-6/12  md:mr-auto" >
                                <div className="text-center ">
                                    <h1 className="text-white font-bold text-4xl md:text-6xl lg:text-7xl  ">
                                        CEO & Academic Director's Message
                                    </h1>
                                    <p className="mt-4 mb-4 text-xl md:text-2xl text-gray-300 border border-blue-400 p-1 md:p-4">
                                        <strong>Md Neazul Hoque</strong> <br />

                                    </p>
                                </div>
                            </div>
                            <div className="w-full md:w-6/12 lg:w-6/12 px-4 ml-auto md:block flex justify-center" >
                                <div className="w-full md:w-3/4 p-1 md:p-2">
                                    <img
                                        alt="gallery"
                                        class="block h-full w-full rounded-lg object-cover object-center"
                                        src={ceo_pic}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <section className=" pt-20 pb-20 bg-blue-50 -mt-28">
                <div className="mt-24 flex justify-center">
                    <div className="container px-4 md:px-16 mt-5">
                        <div className='text-justify'>
                           Assalamualaikum Warahmatullah Wabarakatuh.<br /><br />

                            All Praise Belongs to Allah <br /><br />

                            In the Name of Allah, I start!! <br /><br />

                            Dear Esteemed Parents, <br /><br />

                            Welcome to Al-Maghrib International School! Our school is committed to providing a high-quality education that prepares students for a successful future. We create a safe and supportive environment where every student feels valued and respected.
                            <br /><br />
                            We are Humbled to witness the growth and success of each student at our Al-Maghrib International Islamic school. Our commitment to providing a nurturing environment that fosters both academic excellence and moral development remains unwavering.
                            <br /><br />
                            Our focus is on academic excellence, character building, and Islamic values. Our experienced teachers offer a Holistic curriculum that meets international standards, helping students reach their full potential.
                            <br /><br />
                            We advocate that cultivating one's character is crucial for life achievements. Through a focused character development program and our foundational Islamic values, we stress the significance of exemplary behavior. Our students are educated on Islamic principles, guiding them towards a purposeful and satisfying life.
                            <br /><br />
                            Let us work hand in hand to create an environment where every child can thrive academically, spiritually, and socially.
                            <br /><br />
                            As the CEO & Academic Director, I am dedicated to ensuring our students receive the best possible education. Please visit our School to learn more about our school and programs.
                            <br /><br />
                            Thank you for entrusting us with the education of your children. We look forward to a year filled with growth, achievement, and meaningful experiences.
                            <br /><br />
                            Best regards, <br />
                            <b>Md Neazul Hoque</b> <br />
                            CEO & Academic Director

                        </div>
                    </div>
                </div>
            </section>
        </div>
       </>
    );
};

export default CEO;
import React, { useEffect } from 'react';
import principal from '../../../assets/img/board_members/helal.png'
import ceo from '../../../assets/img/board_members/ceo1.jpg'
import vice_principal from '../../../assets/img/board_members/emdad.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown, faListCheck, faPeopleRoof } from '@fortawesome/free-solid-svg-icons';
import school from '../../../assets/img/school/newSchool.png';
import Meta from '../../meta/Meta';

const Management = () => {
    const pageDescription = "Executive board members of Al-Maghrib. Check out the Experiences.";
    const pageImage = "https://i.ibb.co/gwkTRWk/new-School.png"
    const pageUrl = "https://almaghribschool.com/about/values";
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = 'Management | Al-Maghrib';
    }, []);
    return (
        <>
            <Meta pageTitle={"Management | Al-Maghrib"} pageDescription={pageDescription} pageImage={pageImage} pageUrl={pageUrl} />
            <div>
                <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
                    style={{
                        minHeight: "45vh"
                    }}>
                    <div className="absolute top-0 w-full h-full bg-center bg-cover"
                        style={{
                            // backgroundImage: "url('https://c0.wallpaperflare.com/preview/764/602/552/business-professional-sign.jpg')"
                            // backgroundImage: `url('${school}')`
                            backgroundImage: "url('https://c4.wallpaperflare.com/wallpaper/743/421/76/geometry-abstract-lines-digital-art-wallpaper-preview.jpg')"
                            // backgroundImage: "url('https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?w=400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjJ8fGJvYXJkfGVufDB8fDB8fHww')"

                        }}>
                        <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
                    </div>
                    <div className="container relative mx-auto">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full px-2 md:px-4 ml-auto mr-auto text-center">
                                <div className="">
                                    <h1 className="text-white font-semibold text-4xl md:text-5xl">
                                        Management
                                    </h1>
                                    <p className="mt-4 text-lg text-gray-300">
                                        <strong>Executive board of Al-Maghrib International School</strong> <br />
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <section className="pt-20 pb-20 bg-blue-50 -mt-24">
                    <div className="container mx-auto px-4 mt-5">
                        <div className="lg:pt-12 pt-6 w-full md:w-12/12 text-center">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                <div className="px-4 py-5 flex-auto">
                                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                                        <FontAwesomeIcon icon={faPeopleRoof} size='lg' />
                                    </div>
                                    <h6 className="text-xl font-semibold">Board of Management</h6>
                                    <div className="flex flex-wrap mt-12">
                                        <div className="w-full md:w-4/12 lg:w-4/12 lg:mb-0 mb-12 px-0 md:px-4">
                                            <div className="px-0 md:px-6">
                                                <img
                                                    alt="..."
                                                    src={principal}
                                                    className="shadow-lg rounded-full max-w-full mx-auto"
                                                    style={{ maxWidth: "120px" }}
                                                />
                                                <div className="pt-6 text-center">
                                                    <h5 className="text-xl font-bold">
                                                        M MOHIBBULLAH HELAL
                                                    </h5>
                                                    <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
                                                        Chairman & Principal <br /> Al-Maghrib International School
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full md:w-4/12 lg:w-4/12 lg:mb-0 mb-12 px-0 md:px-4">
                                            <div className="px-0 md:px-6">
                                                <img
                                                    alt="..."
                                                    src={ceo}
                                                    className="shadow-lg rounded-full max-w-full mx-auto"
                                                    style={{ maxWidth: "120px" }}
                                                />
                                                <div className="pt-6 text-center">
                                                    <h5 className="text-xl font-bold">
                                                        MD NEAZUL HOQUE
                                                    </h5>
                                                    <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
                                                        CEO & Academic Director <br /> Al-Maghrib International School
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full md:w-4/12 lg:w-4/12 lg:mb-0 mb-12 px-0 md:px-4">
                                            <div className="px-0 md:px-6">
                                                <img
                                                    alt="..."
                                                    src={vice_principal}
                                                    className="shadow-lg rounded-full max-w-full mx-auto"
                                                    style={{ maxWidth: "120px" }}
                                                />
                                                <div className="pt-6 text-center">
                                                    <h5 className="text-xl font-bold">
                                                        MOHAMMAD EMDAD ULLAH
                                                    </h5>
                                                    <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
                                                        Vice-Principal <br /> Al-Maghrib International School
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="lg:pt-6 pt-6 w-full md:w-12/12 ">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                <div className="px-4 py-5 flex-auto">
                                    <div className="text-center my-5">
                                        {/* <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                                        <FontAwesomeIcon icon={faStar} size="lg" />
                                    </div> */}
                                        <h6 className="text-xl font-semibold">Md Neazul Hoque</h6>
                                        <p className="mt-1 text-sm text-gray-500  font-semibold">
                                            CEO & Academic Director
                                        </p>
                                    </div>

                                    <div className="lg:flex">
                                        <div className="pt-6 w-full md:w-3/12 px-4 ">
                                            <h6 className="text-center lg:text-left text-xl font-semibold">Experience</h6>
                                        </div>
                                        <div className=" lg:pt-6 w-full md:w-9/12 px-4 ">
                                            <div class="divide-y divide-slate-200 ">
                                                <div>
                                                    <p class="mb-3  font-normal text-gray-700 dark:text-gray-400">
                                                        Worked for 10 years at CIDER-International School and was a member of the Senior Management Team.
                                                    </p>
                                                </div>
                                                <div>
                                                    <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                        Worked as Advisor/Principal Envoy at Safeer Academy.
                                                    </p>
                                                </div>
                                                <div>
                                                    <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                        Worked for companies such as The Peninsula, Chittagong, Bangalion Communication Ltd and Pubali Bank Ltd at the start of his career.
                                                    </p>
                                                </div>
                                                <div>
                                                    <p class=" mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                        Worked as a Trainer/Facilitator for the last 6 years and has given training in schools and in corporates.

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="lg:flex">
                                        <div className="pt-6 w-full md:w-3/12 px-4 ">
                                            <h6 className="text-center lg:text-left text-xl font-semibold">Educational Qualification</h6>
                                        </div>
                                        <div className=" lg:pt-6 w-full md:w-9/12 px-4 ">
                                            <div class="divide-y divide-slate-200 ">
                                                <div>
                                                    <p class="mb-3  font-normal text-gray-700 dark:text-gray-400">
                                                        O level and A level
                                                    </p>
                                                </div>
                                                <div>
                                                    <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                        BBA in Marketing from Independent University (IUB)
                                                    </p>
                                                </div>
                                                <div>
                                                    <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                        MBA in Human Resources Management from Chittagong Independent University (CIU)
                                                    </p>
                                                </div>
                                                <div>
                                                    <p class=" mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                        Currently pursuing a Bachelor of Islamic Studies from the International Open University of Dr. Bilal Philip, and completed almost 2 and half years of study in Islamic studies.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="lg:pt-6 pt-6 w-full md:w-12/12 ">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                <div className="px-4 py-5 flex-auto">
                                    <div className="text-center my-5">
                                        {/* <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                                        <FontAwesomeIcon icon={faStar} size="lg" />
                                    </div> */}
                                        <h6 className="text-xl font-semibold">Mohammad Emdad Ullah</h6>
                                        <p className="mt-1 text-sm text-gray-500  font-semibold">
                                            Vice Principal
                                        </p>
                                    </div>

                                    <div className="lg:flex">
                                        <div className="pt-6 w-full md:w-3/12 px-4 ">
                                            <h6 className="text-center lg:text-left text-xl font-semibold">Experience</h6>
                                        </div>
                                        <div className=" lg:pt-6 w-full md:w-9/12 px-4 ">
                                            <div class="divide-y divide-slate-200 ">
                                                <div>
                                                    <p class="mb-3  font-normal text-gray-700 dark:text-gray-400">
                                                        Teacher at Madrasah Omar Faruk Al-Islamiah, Chittagong for two years
                                                    </p>
                                                </div>
                                                <div>
                                                    <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                        Teacher at Darut Taqwa Tahfizul Quran Madrasah
                                                    </p>
                                                </div>
                                                <div>
                                                    <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                        Teacher at Al Humaira R. Girls Fazil Madrasah
                                                    </p>
                                                </div>
                                                <div>
                                                    <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                        Teacher at Darul Arqam Madrasah
                                                    </p>
                                                </div>
                                                <div>
                                                    <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                        Visitor Khatib at Colonel Hat CDA Masjid
                                                    </p>
                                                </div>
                                                <div>
                                                    <p class=" mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                        Visitor Khatib at Bonanisha Jame Masjid

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="lg:flex">
                                        <div className="pt-6 w-full md:w-3/12 px-4 ">
                                            <h6 className="text-center lg:text-left text-xl font-semibold">Educational Qualification</h6>
                                        </div>
                                        <div className=" lg:pt-6 w-full md:w-9/12 px-4 ">
                                            <div class="divide-y divide-slate-200 ">
                                                <div>
                                                    <p class="mb-3  font-normal text-gray-700 dark:text-gray-400">
                                                        Takmil AL-Hadith (Masters) <br />
                                                        Institution: Al-Jamiah Al-Islamiah, Patiya <br />
                                                        Area: Islamic Law, Al-Hadith, Tafsir, Arabic Language & Literature, Aqayed, Inheritance Science, Usul Al-Fiqh <br />
                                                        Session: 2015 - 2016 <br />
                                                        Board: Ettihadul Madaris, Bangladesh
                                                    </p>
                                                </div>
                                                <div>
                                                    <p class="mb-3  font-normal text-gray-700 dark:text-gray-400">
                                                        Sanuviah <br />
                                                        Institution: Jamiah Darul Ma'arif Al-Islamiah, Chittagong <br />
                                                        Session: 2013 - 2014
                                                    </p>
                                                </div>
                                                <div>
                                                    <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                        MA <br />
                                                        Institution: University of Chittagong <br />
                                                        Department: Arabic
                                                    </p>
                                                </div>
                                                <div>
                                                    <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                        B.A (Hon's) <br />
                                                        Institution: University of Chittagong <br />
                                                        Department: Arabic
                                                    </p>
                                                </div>
                                                <div>
                                                    <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                        Fazil <br />
                                                        Institution: Islamic Arabic University
                                                    </p>
                                                </div>
                                                <div>
                                                    <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                        H.S.C (Alim) <br />
                                                        Institution: Majidia Islamiah Alim Madrasah <br />
                                                        Group: Humanities
                                                    </p>
                                                </div>
                                                <div>
                                                    <p class="mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                        S.S.C (Dakhil) <br />
                                                        Institution: Madrasah-E Abu Hurairah <br />
                                                        Group: Humanities
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Management;
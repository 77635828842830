import { faFacebookF, faLinkedinIn, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faInfo, faQuestion, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react';
import school from '../../../assets/img/school/newSchool.png';
import emailjs from '@emailjs/browser';

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = 'Contact | Al-Maghrib';
    }, []);
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_ouvy0b5', 'template_2s7b8fc', form.current, '-AZJ12MqV34imZCZD')
            .then((result) => {
                alert("Thank you for your query. We will get in touch with you soon.");
                form.reset();
            }, (error) => {
                console.log(error.text);
            });

    };
    return (
        <div>
            <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
                style={{
                    minHeight: "45vh"
                }}>
                <div className="absolute top-0 w-full h-full bg-center bg-cover"
                    style={{
                        backgroundImage: "url('https://c1.wallpaperflare.com/preview/551/930/440/contact-us-contact-call-us-phone-communication.jpg')"


                    }}>
                    <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
                </div>
                <div className="container relative mx-auto">
                    <div className="items-center flex flex-wrap">
                        <div className="w-full px-2 md:px-4 ml-auto mr-auto text-center">
                            <div className="">
                                <h1 className="text-white font-semibold text-4xl md:text-5xl">
                                    Connect With Us
                                </h1>
                                <p className="mt-4 text-lg text-gray-300">
                                    <strong>Get in touch with Al-Maghrib International School</strong> <br />
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <section className="pt-20 pb-20 bg-blue-50 -mt-24">
                <div className="container mx-auto px-4 mt-5">

                    <div className="lg:pt-12 pt-6 container mx-auto ">
                        <div className="flex flex-wrap justify-center">
                            <div className="w-full lg:w-6/12 px-0 md:px-4 ">

                                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
                                    <form ref={form} onSubmit={sendEmail}>
                                        <div className="flex-auto p-5 lg:p-10">
                                            <div className="flex justify-center text-center">
                                                <div className="text-white p-3 items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                                                    <FontAwesomeIcon icon={faQuestion} size="lg" />
                                                </div>
                                            </div>
                                            <h4 className="text-2xl font-semibold text-center">
                                                Do you have any queries?
                                            </h4>
                                            {/* <p className="leading-relaxed mt-1 mb-4 text-gray-600">
                                                            Complete this form and we will get back to you within a few hours.
                                                        </p> */}
                                            <div className="relative w-full mb-3 mt-8">
                                                <label
                                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                    htmlFor="full-name"
                                                >
                                                    Full Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                                    placeholder="Full Name"
                                                    name='full_name'
                                                    style={{ transition: "all .15s ease" }}
                                                    required
                                                />
                                            </div>

                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                    htmlFor="email"
                                                >
                                                    Email
                                                </label>
                                                <input
                                                    type="email"
                                                    name='email'
                                                    className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                                    placeholder="Email"
                                                    style={{ transition: "all .15s ease" }}
                                                    required
                                                />
                                            </div>

                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                    htmlFor="phone"
                                                >
                                                    Contact No
                                                </label>
                                                <input
                                                    type="tel"
                                                    name='phone'
                                                    className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                                    placeholder="Your contact no"
                                                    style={{ transition: "all .15s ease" }}
                                                    required
                                                />
                                            </div>

                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                    htmlFor="message"
                                                >
                                                    Message
                                                </label>
                                                <textarea
                                                    rows="5"
                                                    cols="80"
                                                    name='message'
                                                    className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                                    placeholder="Type a message..."
                                                    required
                                                />
                                            </div>
                                            <div className="text-center mt-6">
                                                <button
                                                    className="bg-blue-400 text-white active:bg-blue-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                                    type="submit"
                                                    style={{ transition: "all .15s ease" }}
                                                >
                                                    Send Message
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="w-full lg:w-6/12 px-0 md:px-4 ">
                                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
                                    <div className="flex-auto p-5 lg:p-10">
                                        <div className="flex justify-center text-center">
                                            <div className="text-white p-3 items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                                                <FontAwesomeIcon icon={faInfo} size="lg" />
                                            </div>
                                        </div>
                                        <h4 className="text-2xl font-semibold text-center">
                                            Contact Information
                                        </h4>

                                        <div className="relative w-full mb-3 mt-8">
                                            <h1
                                                className="block  text-gray-700  font-bold mb-2"
                                            >
                                                Our Office
                                            </h1>
                                            <p>
                                                House-131/1, Road-01, South Khulshi, Chittagong, Bangladesh
                                            </p>
                                        </div>

                                        <div className="relative w-full mb-3 mt-8">
                                            <h1
                                                className="block  text-gray-700  font-bold mb-2"
                                            >
                                                Open Office Hours
                                            </h1>
                                            <div>
                                                <p>
                                                    Sun-Thu: 9AM - 3PM</p>
                                                <p>
                                                    Fri & Sat: Closed</p>
                                            </div>
                                        </div>

                                        <div className="relative w-full mb-3 mt-8">
                                            <h1
                                                className="block  text-gray-700  font-bold mb-2"
                                            >
                                                Phone & Email
                                            </h1>
                                            <p>
                                                +880-1766-386905 <br />
                                                +880-1835-348137 <br />
                                                Email: info@almaghribschool.com
                                            </p>
                                        </div>

                                        <div className="relative w-full mb-3 mt-8">
                                            <h1
                                                className="block  text-gray-700  font-bold mb-2"
                                            >
                                                Find Us Online
                                            </h1>
                                            <section class="flex">
                                                <div className='text-white p-3 flex justify-center items-center w-10 h-10 mb-5 shadow-lg rounded-full bg-blue-400 mr-2' >
                                                    <a href="https://www.facebook.com/almaghribschool">
                                                        <FontAwesomeIcon icon={faFacebookF} size='lg' />
                                                    </a>
                                                </div>
                                                <div className='text-white p-3 flex justify-center items-center w-10 h-10 mb-5 shadow-lg rounded-full bg-blue-400 mr-2' >
                                                    <a href="https://www.youtube.com/channel/UC8Za4oYaCnNIohZXvXXEkUw">
                                                        <FontAwesomeIcon icon={faYoutube} size='lg' />
                                                    </a>
                                                </div>
                                                <div className='text-white p-3 flex justify-center items-center w-10 h-10 mb-5 shadow-lg rounded-full bg-blue-400 mr-2' >
                                                    <a href="https://www.linkedin.com/company/almaghribschool/">
                                                        <FontAwesomeIcon icon={faLinkedinIn} size='lg' />
                                                    </a>
                                                </div>
                                            </section>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div>
                        </div>
                    </div> */}

                    {/*  */}
                    {/* <section className="relative block py-24 lg:pt-0 ">
                        <div className="container mx-auto px-4">
                            <div className="flex flex-wrap justify-center">
                                <div className="w-full lg:w-6/12 px-4">
                                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300">
                                        <div className="flex-auto p-5 lg:p-10">
                                            <h4 className="text-2xl font-semibold">
                                                Do you have any queries?
                                            </h4>
                                            <p className="leading-relaxed mt-1 mb-4 text-gray-600">
                                                Complete this form and we will get back to you within a few hours.
                                            </p>
                                            <div className="relative w-full mb-3 mt-8">
                                                <label
                                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                    htmlFor="full-name"
                                                >
                                                    Full Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                                    placeholder="Full Name"
                                                    style={{ transition: "all .15s ease" }}
                                                />
                                            </div>

                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                    htmlFor="email"
                                                >
                                                    Email
                                                </label>
                                                <input
                                                    type="email"
                                                    className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                                    placeholder="Email"
                                                    style={{ transition: "all .15s ease" }}
                                                />
                                            </div>

                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                    htmlFor="message"
                                                >
                                                    Message
                                                </label>
                                                <textarea
                                                    rows="4"
                                                    cols="80"
                                                    className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                                    placeholder="Type a message..."
                                                />
                                            </div>
                                            <div className="text-center mt-6">
                                                <button
                                                    className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                                    type="button"
                                                    style={{ transition: "all .15s ease" }}
                                                >
                                                    Send Message
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}

                </div>
            </section>

        </div>
    );
};

export default Contact;
import { faArrowLeftLong, faArrowRight, faArrowRightLong, faArrowRotateLeft, faDownload, faFileLines, faMagnifyingGlassMinus, faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import HTMLFlipBook from 'react-pageflip';
import './Newsletter.css'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import draft1 from '../../../assets/img/newsletter/front.png'
import draft2 from '../../../assets/img/newsletter/Principal.png'
import draft3 from '../../../assets/img/newsletter/CEO.png'
import draft4 from '../../../assets/img/newsletter/VP.png'
import draft5 from '../../../assets/img/newsletter/1st day of session 23-24.png'
import draft6 from '../../../assets/img/newsletter/wudu and salah week.png'
import draft7 from '../../../assets/img/newsletter/theme and unit 2.png'
import draft8 from '../../../assets/img/newsletter/eid & annual celebration.png'
import draft9 from '../../../assets/img/newsletter/quran recitation.png'
import draft10 from '../../../assets/img/newsletter/math and art.png'
import draft11 from '../../../assets/img/newsletter/local market & BFRI.png'
import draft12 from '../../../assets/img/newsletter/visit to museum & village.png'
import draft13 from '../../../assets/img/newsletter/library & cow farm.png'
import draft14 from '../../../assets/img/newsletter/orphanage and street children.png'
import draft15 from '../../../assets/img/newsletter/Parental involvement.png'
import draft16 from '../../../assets/img/newsletter/seminar.png'
import draft17 from '../../../assets/img/newsletter/teachers training.png'
import draft18 from '../../../assets/img/newsletter/special subject.png'
import draft19 from '../../../assets/img/newsletter/artwork.png'
import draft20 from '../../../assets/img/newsletter/awardees 1.png'
import draft21 from '../../../assets/img/newsletter/awardees 2.png'
import draft22 from '../../../assets/img/newsletter/awardees 3.png'
import draft23 from '../../../assets/img/newsletter/writings 01.png'
import draft24 from '../../../assets/img/newsletter/back side.png'

const Newsletter = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = 'Newsletter | Al-Maghrib';
    }, []);

    // const [source, setSource] = useState("")
    // const [loading, setLoading] = useState()

    // const sour = [
    //     "https://drive.google.com/file/d/1-cnanW0s87AfMACyLTXDOPgaoMA8BnD-/preview",
    //     "https://drive.google.com/file/d/1XcqoPelNtMDrqFtrMQMRi3-VMQxkpeKq/preview",
    //     "https://www.orimi.com/pdf-test.pdf",
    //     "https://res.cloudinary.com/dkadvduok/image/upload/v1703950611/wwyxpayblb4fhrkwesxx.pdf"

    // ]

    // const handleClick = (id) => {
    //     setLoading(true);
    //     setSource(sour[id]);
    //     setLoading(false);
    // }

    const book = useRef();

    const pdfImages = [
        draft1, draft2, draft3, draft4, draft5, draft6, draft7, draft8, draft9, draft10, draft11, draft12, draft13, draft14, draft15, draft16, draft17, draft18, draft19, draft20, draft21, draft22, draft23, draft24,
    ];

    const [numPages, setNumPages] = useState(pdfImages.length);

    const isMobile = window.innerWidth <= 668;

    const onFlip = (e) => {
        // console.log('Current page: ' + e.data);
    };

    return (
        <div>
            <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
                style={{
                    minHeight: "45vh"
                }}>
                <div className="absolute top-0 w-full h-full bg-center bg-cover"
                    style={{
                        backgroundImage: "url('https://images.squarespace-cdn.com/content/v1/5693b76fa128e6b30eb62bc7/1453221585127-1455H38WCLY460L108HK/vintage-%281920x1200%29-wallpaper-6%2Bcopy.jpg?format=2500w')"
                    }}>
                    <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
                </div>
                <div className="container relative mx-auto">
                    <div className="items-center flex flex-wrap">
                        <div className="w-full px-2 md:px-4 ml-auto mr-auto text-center">
                            <div className="">
                                <h1 className="text-white font-semibold text-4xl md:text-5xl">
                                    Newsletter
                                </h1>
                                <p className="mt-4 text-lg text-gray-300">
                                    <strong>July - December 2023: Wisdom Waves</strong> <br />
                                    <strong>Volume 1: Issue 1</strong> <br />
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <section className="pt-20 pb-20 bg-blue-50 -mt-24">
                <div className="container mx-auto px-4 mt-5">
                    <div className="lg:pt-12 pt-6 w-full md:w-12/12 text-center">
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                            {/* <div className="px-4 py-5 flex-auto"> */}
                            {/* <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                                    <FontAwesomeIcon icon={faFileLines} size="lg" />
                                </div>
                                <h6 className="text-xl font-semibold">Our Newsletters</h6>
                                <p></p>
                                <p className="mt-2 mb-4 text-gray-600 ">
                                    To be the best Premium and Transparent Islamic school that represents a holistic and caring environment that
                                    nurtures excellence in academic and moral education. We aim to create successful learners, Responsible Muslim
                                    Citizens, Confident Individuals and accountable leaders that will serve humanity. We also believe in character
                                    before brilliance and every child is unique and every child is capable of learning.
                                </p> */}

                            <br />


                            {/* way 1: iframe link */}
                            {/* <iframe src={source} width="840" height="680" allow="autoplay"></iframe> */}

                            {/* way 2: embedded link */}
                            {/* <div className="w-full lg:w-1/2 mx-auto mt-5">
                                    <div class="embed-responsive" style={{ height: "60vh" }}>
                                        {loading ? "loading" :
                                        <embed
                                        src={source}
                                        type="application/pdf"
                                        width="100%"
                                        height="100%"
                                    />
                                    }
                                    </div>
                                </div> */}

                            {/* way 3: flipbook with zoom */}
                            <div className=" bg-paper  rounded-lg  mx-auto flex justify-center">
                                <div className="bg-paper p-1 lg:p-8 rounded-lg flex flex-col justify-center items-center ">

                                    <TransformWrapper
                                        initialScale={1}
                                        options={{
                                            limitToBounds: false, // Allow scaling beyond the bounds
                                        }}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                                <TransformComponent>
                                                    <div className="flipbook_container  bg-paper  rounded-lg mx-auto flex justify-center">
                                                        <HTMLFlipBook
                                                            width={400}
                                                            height={600}
                                                            size="stretch"
                                                            minWidth={200}
                                                            maxWidth={600}
                                                            minHeight={400}
                                                            maxHeight={1000}
                                                            drawShadow={true}
                                                            flippingTime={1000}
                                                            usePortrait={true}
                                                            startZIndex={0}
                                                            autoSize={true}
                                                            maxShadowOpacity={1}
                                                            showCover={!isMobile}
                                                            mobileScrollSupport={true}
                                                            swipeDistance={30}
                                                            clickEventForward={true}
                                                            useMouseEvents={false}
                                                            renderOnlyPageLengthChange={false}
                                                            onFlip={onFlip}
                                                            ref={book}
                                                            className="rounded-lg shadow-md overflow-hidden"
                                                        >
                                                            {pdfImages.map((image, index) => (
                                                                <div key={index} className="demoPage p-3 mx-auto bg-paper texture-old-book rounded-lg shadow-lg ">
                                                                    <img src={image} alt={`Page ${index + 1}`} className="w-full h-full object-contain" />
                                                                    <div className="mt-2 p-1 page-number absolute bottom-0 left-0 right-0 text-center text-sm text-gray-600">
                                                                        Page {index + 1} of {pdfImages.length}
                                                                    </div>
                                                                </div>

                                                            ))}
                                                        </HTMLFlipBook>
                                                    </div>
                                                </TransformComponent>
                                                <div className="mt-5 mb-4 flex justify-center border border-blue-400 px-0 sm:px-0 md:px-5 py-1">
                                                    <button className='btn p-2 md:p-3 border border-brown-400 mx-1 text-blue-400' onClick={() => book.current.pageFlip().flipPrev()} title="Previous Page"> <FontAwesomeIcon icon={faArrowLeftLong} /></button>
                                                    <button className='btn p-2 md:p-3 border border-brown-400 mx-1 text-blue-400' onClick={() => zoomIn()} title="Zoom In"><FontAwesomeIcon icon={faMagnifyingGlassPlus} /></button>
                                                    <button className='btn p-2 md:p-3 border border-brown-400 mx-1 text-blue-400' onClick={() => zoomOut()} title="Zoom Out"><FontAwesomeIcon icon={faMagnifyingGlassMinus} /></button>
                                                    <button className='btn p-2 md:p-3 border border-brown-400 mx-1 text-blue-400' onClick={() => resetTransform()} title="Reset"><FontAwesomeIcon icon={faArrowRotateLeft} /></button>
                                                    <button className='btn p-2 md:p-3 border border-brown-400 mx-1 text-blue-400' onClick={() => window.location.href = "https://drive.google.com/uc?id=1IgwUjrCbJqFE346TtNBgNPUjUG_CiJxA"} title="Download"><FontAwesomeIcon icon={faDownload} /></button>
                                                    <button className='btn p-2 md:p-3 border border-brown-400 mx-1 text-blue-400' onClick={() => book.current.pageFlip().flipNext()} title="Next Page"> <FontAwesomeIcon icon={faArrowRightLong} /></button>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                </div>
                            </div>



                            <br />

                            {/* <div className="px-4 py-5">
                                <h6 className="text-xl font-semibold">Newsletter: Wisdom Waves</h6>
                                <p></p>
                                <p className="mt-2 mb-4 text-gray-600 ">
                                  July - December 2023
                                </p>
                            </div> */}

                            {/* {sour.map((s, i) => (
                                        <button className='p-5 border border-blue-400' key={i} onClick={() => handleClick(i)}>Newsletter {i + 1}</button>
                                    ))} */}
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Newsletter;